import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/celso.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistcelso.json"


const CelsoPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-secondary"}
    path={"celso"}
    bannerimg={imgbanner}
    title={allcategories.celso.title}
    writeup={allcategories.celso.description} 
    videolist={videolist} />
)

export default CelsoPage
